<template>
  <main>
    <PageTitleContainer>
      <template #default>
        <PageTitle>Produttori</PageTitle>
      </template>
      <template #actions>
        <router-link class="btn btn-outline-primary" :to="{ name: 'suppliers.producers.add' }">Aggiungi</router-link>
      </template>
    </PageTitleContainer>

    <FetchList :loading="loading">
      <div class="container">
        <template v-if="producers && producers.length > 0">
          <AwlTable :heading="tableHeadings" :rows="producers" striped>
            <template #actions-content="{ row }">
              <ActionShow class="ms-2" :to="{ name: 'suppliers.producers.show', params: { id: row.id } }" />
            </template>
          </AwlTable>

          <AwlPagination :value="page" :totalPages="totalPages" @page="goToPage" />
        </template>
        <Alert v-else status="info">Nessun produttore trovato.</Alert>
      </div>
    </FetchList>
  </main>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import pageableMixin from '@/libs/Pagination/mixins/pageable.js';

import FetchList from '@/views/components/Layout/FetchList.vue';
import AwlTable from '@/libs/Table/components/AwlTable.vue';
import ActionShow from '@/libs/Table/components/Actions/ActionShow.vue';
import AwlPagination from '@/libs/Pagination/components/AwlPagination.vue';
import Alert from '@/libs/Feedback/components/Alert.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import PageTitle from '@/views/components/Private/PageTitle.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  $api.listProducers(null, null)
    .then(res => next(vm => {
      vm.producers = res?.data?.producers || [];
      vm.setMetadata(res?.data);
    }))
    .catch(() => next(from))
  ;
}

export default {
  mixins: [pageableMixin],
  components: { FetchList, Alert, AwlTable, ActionShow, AwlPagination, PageTitleContainer, PageTitle },
  data () {
    return {
      loading: false,
      producers: null,
    };
  },
  computed: {
    tableHeadings () {
      return [
        { key: 'name', label: 'Nome' },
        { key: 'zoneName', label: 'Zona' },
        { key: 'nbWines', label: 'Nb. prodotti' },
      ];
    },
  },
  methods: {
    fetchResults (page = this.page) {
      return this.$api.listProducers(page, this.perPage)
        .then(res => {
          this.producers = res?.data?.producers || [];
          this.setMetadata(res?.data);
        })
        .catch(this.$log.error)
      ;
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
